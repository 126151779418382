﻿@mixin icon-chevron-right($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.41 12'%3E%3Cpath fill='#{$fill}' d='M1.41 0L0 1.41 4.58 6 0 10.59 1.41 12l6-6z'/%3E%3C/svg%3E"), "#", "%23")
}

@mixin icon-chevron-left($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.41 12'%3E%3Cpath fill='#{$fill}' d='M6,0,7.41,1.41,2.83,6l4.58,4.59L6,12,0,6Z'/%3E%3C/svg%3E"), "#", "%23")
}

@mixin icon-chevron-down($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7.41'%3E%3Cpath fill='#{$fill}' d='M12 1.41L10.59 0 6 4.58 1.41 0 0 1.41l6 6z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-wing($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 762.53 394.9'%3E%3Cpath fill='#{$fill}' d='M595.16,44.89l123.27,0L762.53,0H41.71l89.2,44.82,439.32.07c-4.81,5-9.7,10.49-14.56,16.37H0l89.76,44.85,435.4.07a255.05,255.05,0,0,0-12.52,26.74H143.23l89.61,44.88,266.84.08c-.07.22-1.83,11.14-2.43,16.31H264.76l90.53,45h140.4c.3,5.56.8,11,1.44,16.37l-109.28-.06,89.9,44.88h29.61c15.17,44.5,43.21,77.48,75.1,91.27a73,73,0,0,0,7.37,3.27l-.38-.57h0c-34.82-48.08-34.32-96.8-21.49-138.75l-54.54,0c-.63-5.28-1.08-10.78-1.35-16.37h61.15c6-15.7,13.55-30.62,21.09-45H513.5c.52-5.1,2-16.09,2.06-16.31l88.25.06s12.08-17.26,15.76-22.3C623.66,150,638,132.92,638,132.92H529a222.49,222.49,0,0,1,14.28-26.74l117.72,0,41.18-44.92-123.66,0C584.05,55.44,589.63,50,595.16,44.89Z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-envelope($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M48,8.471v31.061L30.201,24.699L48,8.471z M0,8.472v31.06l17.799-14.832L0,8.472z M46.644,7H1.355L24,27.646L46.644,7z M24,30.354l-4.709-4.293L1.363,41h45.273L28.709,26.06L24,30.354z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-arrow-circle-down($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M2,24c0,12.131,9.869,22,22,22s22-9.869,22-22S36.131,2,24,2S2,11.869,2,24z M44,24c0,11.027-8.973,20-20,20S4,35.027,4,24 S12.973,4,24,4S44,12.973,44,24z M23,34.586V10h2v24.586l8.293-8.293l1.414,1.414L24,38.414L13.293,27.707l1.414-1.414L23,34.586z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-arrow-circle-up($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M46 24c0-12.1-9.9-22-22-22S2 11.9 2 24s9.9 22 22 22 22-9.9 22-22zM4 24C4 13 13 4 24 4s20 9 20 20-9 20-20 20S4 35 4 24zm21-10.6V38h-2V13.4l-8.3 8.3-1.4-1.4L24 9.6l10.7 10.7-1.4 1.4-8.3-8.3z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-download($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M43.46 16.93l-19.7 19.7-19.7-19.7H15.3V0h16.92v16.93zM4.06 42.31h39.4V48H4.06z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-remove($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M7.75 7.75A22.14 22.14 0 0 1 24 1a22.16 22.16 0 0 1 16.25 6.75A22.16 22.16 0 0 1 47 24a22.14 22.14 0 0 1-6.75 16.25A22.16 22.16 0 0 1 24 47a22.14 22.14 0 0 1-16.25-6.75A22.14 22.14 0 0 1 1 24 22.16 22.16 0 0 1 7.75 7.75zm27.8 24.56L27.24 24l8.31-8.31-3.24-3.24L24 20.76l-8.31-8.31-3.24 3.24L20.76 24l-8.31 8.31 3.24 3.24L24 27.24l8.31 8.31z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-tag($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M46.65 23a4.91 4.91 0 0 1 0 6.76l-16.9 16.9a4.91 4.91 0 0 1-6.76 0L1.35 25A4.57 4.57 0 0 1 0 21.63V4.73a4.55 4.55 0 0 1 1.41-3.32A4.55 4.55 0 0 1 4.73 0h16.9A4.57 4.57 0 0 1 25 1.35zM5.8 10.87a3.54 3.54 0 0 0 5.07 0 3.54 3.54 0 0 0 0-5.07 3.54 3.54 0 0 0-5.07 0 3.54 3.54 0 0 0 0 5.07z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-filter($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M0 8.67h48V14H0zm8 18.62V22h32v5.25zm10.63 13.38v-5.38h10.75v5.38z' /%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-magnifying-glass($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M34.38 30.27L48 43.89 43.89 48 30.27 34.38v-2.19l-.78-.77a17 17 0 0 1-11.56 4.24 17.33 17.33 0 0 1-12.66-5.14 17 17 0 0 1-5.2-12.59 17.23 17.23 0 0 1 5.2-12.66A17.23 17.23 0 0 1 17.93.07a17 17 0 0 1 12.59 5.2 17.33 17.33 0 0 1 5.14 12.66 17 17 0 0 1-4.24 11.56l.77.78zm-25.19-3.6a11.89 11.89 0 0 0 8.74 3.6 11.91 11.91 0 0 0 8.74-3.6 11.93 11.93 0 0 0 3.6-8.74 11.93 11.93 0 0 0-3.6-8.74 11.91 11.91 0 0 0-8.74-3.6 11.89 11.89 0 0 0-8.74 3.6 11.89 11.89 0 0 0-3.6 8.74 11.89 11.89 0 0 0 3.6 8.74z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-location($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M43.46 21.88H48v4.29h-4.54a18.88 18.88 0 0 1-5.61 11.73 18.89 18.89 0 0 1-11.73 5.61V48h-4.29v-4.49A18.94 18.94 0 0 1 10.1 37.9a18.94 18.94 0 0 1-5.61-11.73H0v-4.29h4.49a18.89 18.89 0 0 1 5.61-11.73 18.88 18.88 0 0 1 11.73-5.61V.05h4.29v4.49a18.84 18.84 0 0 1 11.73 5.61 18.84 18.84 0 0 1 5.61 11.73zM13.21 34.79A14.67 14.67 0 0 0 24 39.23a14.66 14.66 0 0 0 10.76-4.44A14.67 14.67 0 0 0 39.18 24a14.66 14.66 0 0 0-4.44-10.76A14.66 14.66 0 0 0 24 8.82a14.67 14.67 0 0 0-10.77 4.44A14.66 14.66 0 0 0 8.77 24a14.67 14.67 0 0 0 4.44 10.79zm4.65-16.89a8.62 8.62 0 0 1 12.24 0 8.62 8.62 0 0 1 0 12.24 8.6 8.6 0 0 1-12.24 0 8.6 8.6 0 0 1 0-12.24z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-phone($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M9.62 20.75a40 40 0 0 0 17.63 17.62l5.87-5.87a2.36 2.36 0 0 1 2.75-.63 29.86 29.86 0 0 0 9.5 1.5A2.56 2.56 0 0 1 48 36v9.37A2.56 2.56 0 0 1 45.37 48a43.72 43.72 0 0 1-32.06-13.31A43.75 43.75 0 0 1 0 2.62 2.56 2.56 0 0 1 2.62 0H12a2.56 2.56 0 0 1 2.62 2.62 29.81 29.81 0 0 0 1.5 9.5 2.54 2.54 0 0 1-.62 2.75z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-facebook($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$fill}' d='M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z' /%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-twitter-x($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1668.56 1221.19'%3E%3Cpath fill='#{$fill}' d='m336.33 142.251 386.39 516.64-388.83 420.05h87.51l340.42-367.76 275.05 367.76h297.8l-408.13-545.7 361.92-390.99h-87.51l-313.51 338.7-253.31-338.7h-297.8zm128.69 64.46h136.81l604.13 807.76h-136.81l-604.13-807.76z' /%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-linkedin($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$fill}' d='M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z' /%3E %3C/svg%3E"), "#", "%23");
}

@mixin icon-info($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='#{$fill}' d='M7 7a23.15 23.15 0 0 1 17-7 23.15 23.15 0 0 1 17 7 23.15 23.15 0 0 1 7 17 23.15 23.15 0 0 1-7 17 23.15 23.15 0 0 1-17 7 23.15 23.15 0 0 1-17-7 23.15 23.15 0 0 1-7-17A23.15 23.15 0 0 1 7 7zm3.38 30.54A18.54 18.54 0 0 0 24 43.27a18.56 18.56 0 0 0 13.58-5.69A18.54 18.54 0 0 0 43.27 24a18.56 18.56 0 0 0-5.69-13.58A18.56 18.56 0 0 0 24 4.73a18.54 18.54 0 0 0-13.58 5.69A18.56 18.56 0 0 0 4.73 24a18.54 18.54 0 0 0 5.69 13.58zm11.25-20.75v-4.85h4.74v4.85zm0 19.27V21.63h4.74v14.43z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-check($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$fill}' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon-calculator($fill) {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 297 297'%3E%3Cpath fill='#{$fill}' d='M222.257 0H74.743C54.408 0 37.865 16.543 37.865 36.878v223.243c0 20.335 16.543 36.878 36.878 36.878h147.513c20.335 0 36.878-16.543 36.878-36.878V36.878C259.135 16.543 242.592 0 222.257 0zM74.743 19.934h147.513c9.343 0 16.944 7.601 16.944 16.944v48.867H57.799V36.878c0-9.343 7.601-16.944 16.944-16.944zm147.514 257.132H74.743c-9.343 0-16.944-7.601-16.944-16.944V105.679H239.2v154.442c.001 9.344-7.6 16.945-16.943 16.945z'/%3E%3Cpath fill='#{$fill}' d='M128.139 195.091h-38.32c-5.504 0-9.967 4.463-9.967 9.967v38.32c0 5.504 4.463 9.967 9.967 9.967h38.32c5.504 0 9.967-4.463 9.967-9.967v-38.32c0-5.504-4.463-9.967-9.967-9.967zm-9.968 38.32H99.786v-18.386h18.386v18.386zM207.182 195.091h-38.32c-5.504 0-9.967 4.463-9.967 9.967v38.32c0 5.504 4.463 9.967 9.967 9.967h38.32c5.504 0 9.967-4.463 9.967-9.967v-38.32c0-5.504-4.462-9.967-9.967-9.967zm-9.967 38.32H178.83v-18.386h18.386v18.386zM128.139 120.531h-38.32c-5.504 0-9.967 4.463-9.967 9.967v38.32c0 5.504 4.463 9.967 9.967 9.967h38.32c5.504 0 9.967-4.463 9.967-9.967v-38.32c0-5.504-4.463-9.967-9.967-9.967zm-9.968 38.32H99.786v-18.386h18.386v18.386zM207.182 120.531h-38.32c-5.504 0-9.967 4.463-9.967 9.967v38.32c0 5.504 4.463 9.967 9.967 9.967h38.32c5.504 0 9.967-4.463 9.967-9.967v-38.32c0-5.504-4.462-9.967-9.967-9.967zm-9.967 38.32H178.83v-18.386h18.386v18.386z'/%3E%3C/svg%3E"), "#", "%23");
}
