@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.SearchBoxAutocomplete';

#{$name} {

    &-q {
        border-radius: 5rem;
        font-family: $headings-font-family;
        border: 1px solid $sand-light;
        padding: .5rem 2.5rem;
        max-width: 950px;
        width: 100%;
        display: block;
        margin: .5rem auto;
        z-index: 25 !important;
        outline: none;

        @include icon-magnifying-glass($gray-600);
        background-size: 17px 17px;
        background-repeat: no-repeat;
        background-position: 15px 50%;

        &::-webkit-search-cancel-button {
            color: $primary;
        }

        &:focus {
            border-color: $primary-light;
        }

        @include media-breakpoint-up(md) {
            padding: .75rem 2.5rem;
            font-size: 1rem;
            margin: 1.5rem auto;
        }
    }

    &-typeaheadMenu {
        padding: 15px;
        background-color: $white;
        text-align: left;
        top: -1rem !important;
        box-shadow: 0 15px 15px rgba($black, .09);
        z-index: 20 !important;
        border-radius: 3px;
        padding-top: 4.5rem;
        left: -15px !important;
        right: -15px !important;

        &[style*=none] {
            background-color: transparent;
            box-shadow: none;
        }


        @include media-breakpoint-up(md) {
            padding-top: 5.5rem;
        }

        @include media-breakpoint-up(lg) {
            box-shadow: 0 0 15px rgba($black, .09);
            left: auto !important;
            right: auto !important;
            width: 100%;
            padding-top: 5.5rem;
        }
    }
    //Cannot change this classname, it breaks SUIT but not much to do here.
    .twitter-typeahead {
        display: block !important;
    }

    &-typeaheadOpen {
        display: flex !important;
        flex-wrap: wrap;
    }

    &-showAllLink {
        font-size: .625rem;
    }
    // Perfect match
    &-typeaheadDataset {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 33.3%;
        }
        /*&-0 {
            width: 100% !important;
        }*/
    }

    &-suggestionImage {
        max-width: 50px;
    }

    &-autocompleteHeader {
        @include media-breakpoint-down(sm) {
            font-size: .875rem;
        }
    }

    &-autocompleteLink {
        &:hover {
            text-decoration: none;
        }
    }

    &-title {
        font-size: .75rem;

        #{$name}-autocompleteLink:hover & {
            text-decoration: underline;
        }
    }

    &-subHeading {
        font-size: .75rem;
        color: $gray-600;
    }
}


//When search is from the header
.MainNavigation-search {
    #{$name}-typeaheadMenu {
        position: static !important;
        box-shadow: none;
        padding-top: 0;
    }
}